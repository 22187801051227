import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"min-width":"60%","max-width":"90%","persistent":""},model:{value:(_vm.modal.dialog),callback:function ($$v) {_vm.$set(_vm.modal, "dialog", $$v)},expression:"modal.dialog"}},[_c(VCard,[_c('div',{staticClass:"d-flex flex-column flex-sm-row align-center justify-space-between"},[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.modal.title)+" ")]),(!_vm.modal.hidePlusButton)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","small":"","color":"accent"},on:{"click":function($event){_vm.modal.plusButtonClicked = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,false,3490857288)},[_c('span',[_vm._v("Adicionar")])]):_vm._e()],1),_c(VCardText,[(_vm.modal.component)?_c(_vm.modal.component,{tag:"component"}):_c('h2',{staticClass:"text-center text-h5"},[_vm._v("Nada há ser mostrado!")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),(_vm.modal.hideButtonReturn)?_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Fechar ")]):_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.modal.returnButtonClicked = true}}},[_vm._v(" Voltar ")]),(!_vm.modal.hideButtonSave)?_c(VBtn,{attrs:{"loading":_vm.modal.loadingButtonSave,"color":"primary"},on:{"click":function($event){_vm.modal.saveButtonClicked = true}}},[_vm._v(" Salvar ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }