<template>
  <section>
    <v-row>
      <v-col cols="12">
        <CardUsuario />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs
          class="mt-2"
          v-model="tab"
          color="accent"
          background-color="transparent"
          style="box-shadow: none !important"
        >
          <v-tab v-for="item in tabs" :key="item.id" @click="viewContent(item)">
            {{ item.nome }}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          class="mt-6"
          style="background-color: transparent !important"
          v-model="tab"
        >
          <v-tab-item v-for="item in tabs" :key="item.id">
            <component :is="item.component"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <GenericModal />
    <v-overlay :value="loading">
      <h1 class="primary--text text-h4 font-weight-medium mb-3">Carregando</h1>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-overlay>
  </section>
</template>

<script>
import CardUsuario from "../components/CardUsuario.vue";
import GenericModal from "../components/GenericModal.vue";

export default {
  name: "PainelCandidato",
  inject: ["$validator"],
  components: {
    CardUsuario,
    GenericModal,
  },
  data() {
    return {
      tab: null,
      items: ["web", "shopping", "videos", "images", "news"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

      tabs: [
        {
          id: 1,
          nome: "Informações",
          component: () => import("../components/InformacoesTab.vue"),
        },
        {
          id: 2,
          nome: "Vagas",
          card_nome: "vaga",
          component: () => import("../components/VagasTab.vue"),
        },
        {
          id: 3,
          nome: "Processos Seletivos",
          card_nome: "processo",
          component: () => import("../components/ProcessosTab.vue"),
        },
      ],

      loading: true,
    };
  },
  async created() {
    await this.$store.dispatch("resCandidatos/getDataPainelCandidato");

    this.loading = false;
  },
  methods: {
    /**
     * @description Renderiza os cards componentes de acordo com a tab
     */
    viewContent(item) {
      if (item && item.card_nome) {
        this.$store.dispatch("resCandidatos/setCardSelected", item.card_nome);
      }
    },
  },
};
</script>

<style></style>
