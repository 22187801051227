import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-8 pa-1"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[_c(VAvatar,{staticClass:"ma-1 mr-sm-4 mr-0",attrs:{"rounded":"5","size":"90"}},[(_vm.candidato && _vm.candidato.avatar)?_c(VImg,{attrs:{"src":_vm.candidato.avatar,"height":"6em","width":"6em","cover":""}}):_c(VImg,{attrs:{"src":require('@/assets/images/avatars/1.png'),"height":"6em","width":"6em","cover":""}})],1),_c('div',{staticClass:"d-flex flex-column justify-center",staticStyle:{"width":"90%"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[(_vm.candidato && _vm.candidato.nome)?_c('h2',[_vm._v(" "+_vm._s(_vm._f("nomeAbreviado")(_vm.candidato.nome))+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex flex-row flex-wrap mt-2"},[(_vm.candidato && _vm.candidato.aniversario)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"ma-0 mx-1 pa-0"},'p',attrs,false),on),[_c(VIcon,[_vm._v("mdi-calendar-blank")]),_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(_vm.candidato.aniversario))+" ")],1)]}}],null,false,2404977890)},[_c('span',[_vm._v("Data de aniversário")])]):_vm._e(),(_vm.candidato && _vm.candidato.cidade && _vm.candidato.uf)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"ma-0 mx-1 pa-0"},'p',attrs,false),on),[_c(VIcon,[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.candidato.cidade)+" - "+_vm._s(_vm.candidato.uf)+" ")],1)]}}],null,false,2204061495)},[_c('span',[_vm._v("Localidade")])]):_vm._e(),(_vm.cargoAtual)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"ma-0 mx-1 pa-0"},'p',attrs,false),on),[_c(VIcon,[_vm._v("mdi-briefcase-variant")]),_vm._v(" "+_vm._s(_vm.cargoAtual)+" ")],1)]}}],null,false,3350013016)},[_c('span',[_vm._v("Cargo atual")])]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row mt-2"},_vm._l((_vm.redesSociais),function(item){return _c(VBtn,{key:item.rede,attrs:{"disabled":item.link == null ? true : false,"target":"_blank","href":item.link,"icon":""}},[_c(VIcon,[_vm._v("mdi-"+_vm._s(item.rede))])],1)}),1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }