<template>
  <v-dialog v-model="modal.dialog" min-width="60%" max-width="90%" persistent>
    <v-card>
      <div
        class="d-flex flex-column flex-sm-row align-center justify-space-between"
      >
        <v-card-title class="text-h5 grey lighten-2">
          {{ modal.title }}
        </v-card-title>
        <v-tooltip v-if="!modal.hidePlusButton" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              fab
              small
              color="accent"
              v-bind="attrs"
              v-on="on"
              @click="modal.plusButtonClicked = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar</span>
        </v-tooltip>
      </div>

      <v-card-text>
        <component v-if="modal.component" :is="modal.component"></component>
        <h2 v-else class="text-center text-h5">Nada há ser mostrado!</h2>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="modal.hideButtonReturn"
          color="primary"
          text
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-btn
          v-else
          color="primary"
          text
          @click="modal.returnButtonClicked = true"
        >
          Voltar
        </v-btn>
        <!-- NOTE Criar evento que pegue diretamente nos componentes filhos -->
        <v-btn
          v-if="!modal.hideButtonSave"
          :loading="modal.loadingButtonSave"
          color="primary"
          @click="modal.saveButtonClicked = true"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GenericModal",
  inject: ["$validator"],
  computed: {
    modal: {
      get() {
        return this.$store.getters["resCandidatos/getModal"];
      },
      set(value) {
        this.$store.dispatch("resCandidatos/setModal", value);
      },
    },
  },
  methods: {
    /**
     * @description Fecha o modal voltando a todas configurações iniciais
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    close() {
      const options = {
        title: "",
        dialog: false,
        hideButtonSave: false,
        hideButtonReturn: true,
        hidePlusButton: true,
        component: null,
        nameComponent: null,
        saveButtonClicked: false,
        returnButtonClicked: false,
        plusButtonClicked: false,
      };

      Object.assign(this.modal, options);
    },
  },
};
</script>

<style></style>
