import { VCol } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('CardUsuario')],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTabs,{staticClass:"mt-2",staticStyle:{"box-shadow":"none !important"},attrs:{"color":"accent","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c(VTab,{key:item.id,on:{"click":function($event){return _vm.viewContent(item)}}},[_vm._v(" "+_vm._s(item.nome)+" ")])}),1),_c(VTabsItems,{staticClass:"mt-6",staticStyle:{"background-color":"transparent !important"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c(VTabItem,{key:item.id},[_c(item.component,{tag:"component"})],1)}),1)],1)],1),_c('GenericModal'),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c('h1',{staticClass:"primary--text text-h4 font-weight-medium mb-3"},[_vm._v("Carregando")]),_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }