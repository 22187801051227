<template>
  <v-card class="mt-8 pa-1">
    <div class="d-flex flex-column flex-sm-row">
      <v-avatar class="ma-1 mr-sm-4 mr-0" rounded="5" size="90">
        <v-img
          v-if="candidato && candidato.avatar"
          :src="candidato.avatar"
          height="6em"
          width="6em"
          cover
        ></v-img>
        <v-img
          v-else
          :src="require('@/assets/images/avatars/1.png')"
          height="6em"
          width="6em"
          cover
        ></v-img>
      </v-avatar>

      <div class="d-flex flex-column justify-center" style="width: 90%">
        <div class="d-flex flex-row align-center">
          <h2 v-if="candidato && candidato.nome">
            {{ candidato.nome | nomeAbreviado() }}
          </h2>
        </div>
        <div
          class="d-flex flex-row align-center justify-space-between flex-wrap"
        >
          <!-- Caracteristicas -->
          <div class="d-flex flex-row flex-wrap mt-2">
            <v-tooltip v-if="candidato && candidato.aniversario" bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on" class="ma-0 mx-1 pa-0">
                  <v-icon>mdi-calendar-blank</v-icon>
                  {{ candidato.aniversario | dateFormatBr() }}
                </p>
              </template>
              <span>Data de aniversário</span>
            </v-tooltip>
            <v-tooltip
              v-if="candidato && candidato.cidade && candidato.uf"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on" class="ma-0 mx-1 pa-0">
                  <v-icon>mdi-map-marker</v-icon> {{ candidato.cidade }} -
                  {{ candidato.uf }}
                </p>
              </template>
              <span>Localidade</span>
            </v-tooltip>
            <v-tooltip v-if="cargoAtual" bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on" class="ma-0 mx-1 pa-0">
                  <v-icon>mdi-briefcase-variant</v-icon> {{ cargoAtual }}
                </p>
              </template>
              <span>Cargo atual</span>
            </v-tooltip>
          </div>
          <!-- Redes sociais -->
          <div class="d-flex flex-row mt-2">
            <v-btn
              v-for="item in redesSociais"
              :key="item.rede"
              :disabled="item.link == null ? true : false"
              target="_blank"
              :href="item.link"
              icon
            >
              <v-icon>mdi-{{ item.rede }}</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CardUsuario",
  computed: {
    candidato: {
      get() {
        return this.$store.getters["resCandidatos/getCandidato"];
      },
    },
    experienciasProfissionais: {
      get() {
        return this.$store.getters[
          "resExperienciasProfissionais/getExperienciasProfissionais"
        ];
      },
    },
    redesSociais: {
      get() {
        let redeSociais =
          this.$store.getters["resCandidatosRedesSociais/getRedesSociais"];

        let arrayProcessed = [
          {
            rede: "linkedin",
            link: null,
          },
          {
            rede: "facebook",
            link: null,
          },
          {
            rede: "twitter",
            link: null,
          },
          {
            rede: "github",
            link: null,
          },
        ];

        redeSociais.forEach(function (el) {
          let element = el;
          let index = arrayProcessed.findIndex((e) => e.rede == el.rede);

          switch (el.rede) {
            case "linkedin":
              arrayProcessed.splice(index, 1, element);
              break;
            case "facebook":
              arrayProcessed.splice(index, 1, element);
              break;
            case "twitter":
              arrayProcessed.splice(index, 1, element);
              break;
            case "github":
              arrayProcessed.splice(index, 1, element);
              break;
          }
        });

        return arrayProcessed;
      },
    },
    cargoAtual() {
      if (
        !this.experienciasProfissionais[0] ||
        this.experienciasProfissionais.length == 0
      )
        return "";

      return this.experienciasProfissionais[0].funcao;
    },
  },
};
</script>
